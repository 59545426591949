
import { defineComponent } from 'vue'
import { useStore } from '@/utils/composables'
import { MyndshftPanel } from '@myndshft/panel'

import {
	IWorklistPayer,
	IWorklistProcedureCode,
} from '@server/api/models/fe/worklist'
import { IPayerPlan } from '@server/api/models/fe/payer'

export default defineComponent({
	name: 'confirmation-summary',
	components: {
		MyndshftPanel,
	},
	setup() {
		const store = useStore()

		const payers: IWorklistPayer[] =
			store.getters['worklistUploadWizard/getParameterPayers']

		const procedures: IWorklistProcedureCode[] =
			store.getters['worklistUploadWizard/getParameterProcedures']

		const onRemovePayer = (selectedPayer: IWorklistPayer) => {
			const index = payers.findIndex(payer => payer.id === selectedPayer.id)
			store.dispatch('worklistUploadWizard/removeParameterPayer', index)
		}

		const onRemovePlan = (
			selectedPlan: IPayerPlan,
			selectedPayer: IWorklistPayer
		) => {
			store.dispatch('worklistUploadWizard/removeParameterPlan', {
				payerId: selectedPayer.id,
				plan: selectedPlan,
			})

			if (!selectedPayer.plans.length) {
				onRemovePayer(selectedPayer)
			}
		}

		const onRemoveProcedure = (selectedProcedure: IWorklistProcedureCode) => {
			const index = procedures.indexOf(selectedProcedure)
			store.dispatch('worklistUploadWizard/removeParameterProcedure', index)
		}

		return {
			payers,
			procedures,

			onRemovePayer,
			onRemovePlan,
			onRemoveProcedure,
		}
	},
})

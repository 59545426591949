
import { defineComponent } from 'vue'

import WorklistUploadWizard from '@/components/worklist/wizard/upload/wizard.vue'

export default defineComponent({
	components: {
		WorklistUploadWizard,
	},
	beforeRouteLeave(to, _, next) {
		if (to.name === 'worklist-manager') {
			next()
		} else {
			next(
				confirm(
					'If you leave the wizard, your unsaved changes will be discarded.'
				) as any
			)
		}
	},
})

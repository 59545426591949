
import {
	defineComponent,
	computed,
	onBeforeMount,
	onBeforeUnmount,
	ref,
	watch,
	onMounted,
} from 'vue'
import { useVuexMachine } from '@/store/utils/machine'
import { MachineEvent, NULL_EVENT } from '@/store/utils/machine'
import { useStore } from '@/utils/composables'

import ConfirmationSummary from '@/components/worklist/wizard/upload/confirmation-summary.vue'
import UploadSelect from '@/components/worklist/wizard/upload/upload.vue'
import InputComponent from '@/components/form/input.vue'

import { useWizard } from '@/components/shared/wizard/steps'
import { WizardStep } from '@/components/shared/wizard/types'
import WizardSteps from '@/components/shared/wizard/steps.vue'

import worklistWizardMachine, {
	WorklistWizardStep,
} from '@/store/worklist-upload-wizard/machine'

import {
	IWorklistPayer,
	IWorklistProcedureCode,
	WorklistInclude,
} from '@server/api/models/fe/worklist'

const wizardSteps: WizardStep[] = [
	{
		label: 'Upload CSV',
		icon: 'upload',
	},
	{
		label: 'Confirm',
		icon: 'check',
	},
]

export default defineComponent({
	name: 'worklist-upload-wizard',
	components: {
		[WizardSteps.name]: WizardSteps,
		[ConfirmationSummary.name]: ConfirmationSummary,
		[UploadSelect.name]: UploadSelect,
		[InputComponent.name]: InputComponent,
	},
	setup() {
		const store = useStore()
		const { steps, currentStep } = useWizard(wizardSteps)
		const { state, transition } = useVuexMachine(
			store,
			worklistWizardMachine,
			'worklistUploadWizard'
		)

		const wizardStep = computed(
			() => store.getters['worklistUploadWizard/wizardStep'] as number
		)
		const selectedPayers = computed<IWorklistPayer[]>(() => {
			return store.state.worklistUploadWizard.worklist.parameters.payers
		})
		const selectedProcedureCodes = computed<IWorklistProcedureCode[]>(() => {
			return store.state.worklistUploadWizard.worklist.parameters.procedureCodes
		})

		const canAdvance = computed(() => {
			switch (wizardStep.value) {
				case 0:
					return hasSelectedPayers.value
				case 1:
					return (
						hasSelectedPayers.value &&
						hasSelectedPlans.value &&
						hasSelectedProcedureCodes.value
					)
				default:
					return false
			}
		})

		const hasSelectedPayers = computed(() => {
			return !!selectedPayers.value.length
		})

		const hasSelectedPlans = computed(() => {
			return (
				hasSelectedPayers.value &&
				selectedPayers.value.every(worklistPayer => worklistPayer.plans.length)
			)
		})

		const hasSelectedProcedureCodes = computed(() => {
			return !!selectedProcedureCodes.value.length
		})

		const worklistName = ref('')
		const worklistInclude = WorklistInclude
		const include = ref(WorklistInclude.EVERYTHING)
		const includeActiveCheckbox = ref<HTMLInputElement>()

		onBeforeMount(() => {
			transition(NULL_EVENT)
		})

		onBeforeUnmount(() => {
			store.dispatch('worklistUploadWizard/reset')
		})

		onMounted(() => {
			store.dispatch(
				'worklistUploadWizard/setInclude',
				WorklistInclude.EVERYTHING
			)
		})

		watch(
			() => wizardStep.value,
			wStep => {
				currentStep.value = wStep
			}
		)

		watch(worklistName, name => {
			store.dispatch('worklistUploadWizard/setName', name)
		})

		const isOnStep = (step: WorklistWizardStep) => {
			return state.value.matches(`wizard.steps.${step}`)
		}

		// State Machine Events
		const PREV: MachineEvent = { type: 'PREV' }
		const NEXT: MachineEvent = { type: 'NEXT' }

		const onIncludeExisting = (event: any) => {
			if (event.target.checked && includeActiveCheckbox.value) {
				includeActiveCheckbox.value.checked = false
			}

			include.value = event.target.checked
				? WorklistInclude.NEW_ONLY
				: WorklistInclude.EVERYTHING
			store.dispatch('worklistUploadWizard/setInclude', include.value)
		}

		const onExcludeDeactivated = (event: any) => {
			include.value = event.target.checked
				? WorklistInclude.ACTIVE_ONLY
				: WorklistInclude.EVERYTHING
			store.dispatch('worklistUploadWizard/setInclude', include.value)
		}

		return {
			steps,
			state,
			wizardStep,
			transition,
			isOnStep,

			PREV,
			NEXT,
			canAdvance,

			worklistWizardMachine,
			WorklistWizardStep,

			worklistName,
			selectedPayers,
			include,
			worklistInclude,
			includeActiveCheckbox,

			onIncludeExisting,
			onExcludeDeactivated,
		}
	},
})

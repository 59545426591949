
import { defineComponent, onMounted, ref, watchEffect } from 'vue'
import { useStore } from '@/utils/composables'

import { Alert, toast } from '@/dependencies/sweet-alert'

export default defineComponent({
	name: 'upload-select',
	setup() {
		const store = useStore()

		const dragging = ref(false)
		const uploading = ref(false)
		const worklistPayers = ref(store.getters['worklistUploadWizard/getPayers'])
		const payersLoading = ref(
			store.getters['worklistUploadWizard/getPayersLoading']
		)
		const hasPayerProcedures = ref(
			store.getters['worklistUploadWizard/hasPayerProcedures']
		)

		watchEffect(() => {
			worklistPayers.value = store.getters['worklistUploadWizard/getPayers']
			payersLoading.value =
				store.getters['worklistUploadWizard/getPayersLoading']
			hasPayerProcedures.value =
				store.getters['worklistUploadWizard/hasPayerProcedures']
		})

		const inputId = Math.random().toString(36).substr(2, 10)

		onMounted(() => {
			store.dispatch('worklistUploadWizard/resetPayers')
			store.dispatch('worklistUploadWizard/getAllPayers')
		})

		/*
		 * CSV Upload format
		 *
		 * payer_unique_name,code
		 * aetna,82542
		 * aetna,81162
		 */

		const onUploadFile = async (event: any) => {
			if (!payersLoading.value) {
				dragging.value = true
				uploading.value = true

				const files = event.target.files || event.dataTransfer.files
				let payload: any = new FormData()
				payload = (await files[0].text()) as any

				if (payload) {
					createWorklist(payload)
				}
			}
		}

		const createWorklist = (payload: string) => {
			try {
				const [payers, codes] = parsePayload(payload)
				const parameterPayers: any[] = []

				worklistPayers.value.forEach((worklistPayer: any) => {
					if (payers.has(worklistPayer.uniqueId)) {
						parameterPayers.push(worklistPayer)
					}
				})

				if (parameterPayers.length && codes.size > 0) {
					const procedureCodes = createProcedures(codes as Set<string>)
					store.dispatch(
						'worklistUploadWizard/addParameterPayers',
						parameterPayers
					)
					store.dispatch(
						'worklistUploadWizard/addParameterProcedures',
						procedureCodes
					)
				} else {
					throw new Error('No payers or codes found')
				}
			} catch (error) {
				toast({ type: Alert.ERROR, title: (error as Error).message })
			} finally {
				dragging.value = false
				uploading.value = false
			}
		}

		const createProcedures = (procedureCodes: Set<string>) => {
			const codes = [...procedureCodes]

			// Create default procedures to use store
			const mappedCodes = codes.map(code => {
				return {
					code,
					description: '',
					type: '',
				}
			})

			return mappedCodes
		}

		const parsePayload = (payload: string) => {
			const load = payload.split(/\r\n|\n/)
			load.shift()

			const payers = new Set()
			const codes = new Set()

			for (const item of load) {
				const [payer, code] = item.split(',')

				if (payer && code) {
					payers.add(payer)
					codes.add(code)
				} else if (!payer) {
					throw new Error('A payer is missing')
				} else if (!code) {
					throw new Error('A code is missing')
				}
			}

			return [payers, codes]
		}

		return {
			dragging,
			uploading,
			inputId,
			payersLoading,
			hasPayerProcedures,

			onUploadFile,
		}
	},
})
